import logo from "../../../assets/logos/LogomarkBrandRedSquare.svg";
import { AvatarOrInitials, sizes } from "./AvatarOrInitials";
import { makeStyles } from "../../providers/makeStyles";
import classnames from "classnames";

interface StyleProps {
    withLogo: boolean;
}

const useStyles = makeStyles((theme, { withLogo }: StyleProps) => ({
    avatar: {
        backgroundColor: withLogo ? theme.palette.background.paper : theme.palette.background.low,
        color: theme.palette.secondary.contrastText,
    },
}));

interface OrganisationAvatarProps {
    size?: keyof typeof sizes;
    className?: string;
    alt: string;
    src?: string;
}

export const OrganisationAvatar: React.FCWithChildren<OrganisationAvatarProps> = ({ size, className, alt, src }) => {
    const classes = useStyles({ withLogo: !!src });

    return (
        <AvatarOrInitials alt={alt} src={src || logo} className={classnames(className, classes.avatar)} size={size} />
    );
};
