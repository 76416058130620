import classnames from "classnames";

import { Heading } from "./Heading";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2),
        margin: "0 auto",
        width: "calc(100vw - (100vw - 100%))",
        maxWidth: `min(100vw, ${theme.shape.pageMaxWidth}px)`,
        "&& header": {
            margin: 0,
            padding: 0,
            borderBottom: 0,
            width: "auto"
        }
    }
}));

interface MaxWidthPageProps {
    className?: string;
}

export const MaxWidthPage: React.FCWithChildren<MaxWidthPageProps> = ({ className, children }) => {
    const classes = useStyles();
    return (
        <main className={classnames(classes.page, className)}>
            {children}
        </main>
    );
};

interface MaxWidthPageWithHeadingProps {
    className?: string;
    title: string;
    returnTo?: string;
    actions?: React.ReactNode;
}

export const MaxWidthPageWithHeading: React.FCWithChildren<MaxWidthPageWithHeadingProps> = ({ className, title, returnTo, actions, children }) => (
    <MaxWidthPage className={className}>
        <Heading title={title} returnTo={returnTo} actions={actions}/>
        {children}
    </MaxWidthPage>
);
