import { makeStyles } from "components/providers/makeStyles";
import { Link, LinkProps } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { EventStatusPill } from "./EventStatusPill";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { EventStatus } from "src/graphql/generated/types";
import { ReactNode } from "react";

const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: theme.spacing(2, 0),
        borderTop: `1px solid ${theme.palette.border.light}`,
    },
    name: {
        marginRight: theme.spacing(2),
    },
    namePill: {
        display: "flex",
        alignItems: "center",
    },
    pill: {
        display: "inline-block",
    },
    mobilePill: {
        "&&": {
            display: "inline-block",
            marginTop: theme.spacing(1)
        }
    },
}));

type EventLinkRowProps = {
    link: LinkProps["to"];
    name: ReactNode;
    date: string;
    status: EventStatus;
}

export const EventLinkRow = ({ link, name, date, status }: EventLinkRowProps) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    return (
        <Link to={link} className={classes.wrapper}>
            <div>
                <Stack mb={1}>
                    <div className={classes.namePill}>
                        <Typography variant="button" className={classes.name}>
                            {name}
                        </Typography>
                        {!isMobile && <EventStatusPill className={classes.pill} status={status}/>}
                    </div>
                </Stack>
                <Typography color="textSecondary">{date}</Typography>
                {isMobile && <EventStatusPill className={classes.mobilePill} status={status}/>}
            </div>
            <ChevronRight/>
        </Link>
    );
};
