import { Component } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";
import classnames from "classnames";

import * as HeatActions from "../../../actions/heat";
import * as NotificationActions from "../../../actions/notification";

import { DnDEditHeat } from "./editHeat";
import { Heading } from "../../layout/page/Heading";
import { T } from "../../util/t";
import { JudgeCardTableSkeleton } from "../judging/judgeCard";
import { EditHeatToolbar } from "../judging/EditHeatToolbar";
import { HidePrinting, ShowPrinting } from "../../util/printing";
import { eventDate } from "../eventDate";
import { HeaderLogo } from "../../layout/print/HeaderLogo";
import { Meta } from "src/components/util/Meta";

export const EditHeatPage = connect(({ events }, { match: { params: { id, event_division_id, heat_id } } }) => ({
    event: events.getIn(["mini", parseInt(id)], Immutable.Map()),
    eventId: id,
    heat: events.getIn(["heats", id, parseInt(heat_id)]),
    heatId: parseInt(heat_id),
    returnTo: event_division_id ?
        `/events/${id}/divisions/${event_division_id}/draw` :
        `/events/${id}/schedule/edit`
}))(
    class EditHeatPage extends Component {
        state = {};

        componentDidMount = () => {
            const { dispatch, history, heatId, eventId, returnTo } = this.props;
            dispatch(HeatActions.get(eventId, heatId))
                .catch(e => {
                    if (e.response && e.response.status === 404) {
                        dispatch(NotificationActions.warn("It looks like we can't find the heat you are looking for! Are you sure it's here?"));
                        return history.replace(returnTo);
                    }
                    throw e;
                });
        };

        render = () => {
            const { event, heat, eventId, returnTo } = this.props;

            return (
                <div className={`edit-heats ${classnames((event.isEmpty() || !heat) && "skeleton")}`}>
                    <Meta title={event.get("name")
                        ? "event_schedule_heats_meta_title"
                        : "Event schedule heats | Liveheats"}
                          translationProps={{ eventName: event.get("name") }}
                    />
                    <ShowPrinting>
                        <HeaderLogo/>
                        {event.getIn(["organisation", "name"])} / {event.get("name")} / {eventDate({ date: event.get("date"), window: event.get("days_window") })}
                        <Heading title={heat ? <T heat={heat}>HeatViewPage_title</T> : <T>HeatHeader_empty_title</T>} />
                    </ShowPrinting>

                    <HidePrinting>
                        <Heading
                            title={heat ? <T heat={heat}>EditHeatPage_title</T> : <T>HeatHeader_empty_title</T>}
                            returnTo={returnTo}
                            actions={<EditHeatToolbar eventId={eventId} heat={heat} renderPrint/>}
                            borderless
                        />
                    </HidePrinting>

                    {!event.isEmpty() && heat && heat.get("result") ? <DnDEditHeat heat={heat} eventId={eventId}/> : <JudgeCardTableSkeleton/>}
                </div>
            );
        };
    });
