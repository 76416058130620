import { useCallback } from "react";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { AbcSharp, ArrowForward, CalendarTodaySharp, CheckBoxSharp, FormatListBulletedSharp } from "@mui/icons-material";
import { usePropertyWidgetState } from "./PropertyWidget";
import { useSelectionRequired } from "./useSelectionRequired";
import { useMessageStyles } from "../forms/FormField";
import { ArchiveButton } from "./ArchiveButton";
import { T } from "../../util/t";
import { ListItemContainer } from "../lists/ListItem";
import { ListItemSelectableWithIcon } from "../lists/ListItemSelectable";

export const PropertyTypeStep = ({ closeDialog, setStep, property }) => {
    const { type, setType } = usePropertyWidgetState();
    const { hasError, setSubmitted } = useSelectionRequired(type);
    const onNext = useCallback(() => {
        setSubmitted(true);
        if (type) setStep(1);
    }, [type]);

    const onText = useCallback(() => setType("text"), []);
    const onSelect = useCallback(() => setType("select"), []);
    const onCheckbox = useCallback(() => setType("checkbox"), []);
    const onDate = useCallback(() => setType("date"), []);
    const classes = useMessageStyles();

    const sharedProps = { hasError, disabled: !!property };

    return <>
        <DialogContent>
            <Typography variant="label1"><T>Select field type</T></Typography>
            <ListItemContainer>
                <ListItemSelectableWithIcon {...sharedProps} isSelected={type==="text"} onSelect={onText} icon={<AbcSharp/>} title="Free text or number" description="User can enter any text or number. Best for items such as “Address”, “Sponsors”, “Emergency contact number”, or “Member ID”"/>
                <ListItemSelectableWithIcon {...sharedProps} isSelected={type==="select"} onSelect={onSelect} icon={<FormatListBulletedSharp/>} title="Drop down" description="Users can select from a set of options. Best for items such as “T-shirt size” or “Gender”"/>
                <ListItemSelectableWithIcon {...sharedProps} isSelected={type==="checkbox"} onSelect={onCheckbox} icon={<CheckBoxSharp/>} title="Check box" description="Users can check the box. Best for items such as “COVID Declaration” or other important terms that must be accepted"/>
                <ListItemSelectableWithIcon {...sharedProps} isSelected={type==="date"} onSelect={onDate} icon={<CalendarTodaySharp/>} title="Date picker" description="Users can select a date. Best for items such as “Arrival date” or “Departure date”"/>
                {hasError && <div className={`${classes.message} ${classes.errorMessage}`}><T>You must select a field type</T></div>}
            </ListItemContainer>
        </DialogContent>
        <DialogActions className="sticky-dialog-actions">
            {property && <ArchiveButton property={property} />}

            <Button variant="outlined" onClick={closeDialog}><T>Cancel</T></Button>
            <Button variant="contained" onClick={onNext} endIcon={<ArrowForward/>}>
                <T>Next</T>
            </Button>
        </DialogActions>
    </>;
};
