import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { AccountCircle, ArrowBack, DirectionsRun, Event } from "@mui/icons-material";

import { CREATE_ORGANISATION_PROPERTY, GET_ORGANISATION_WITH_FEDERATION_PROPERTIES } from "../../../graphql/organisation";
import { usePropertyWidgetState } from "./PropertyWidget";
import { useSelectionRequired } from "./useSelectionRequired";
import { useMessageStyles } from "../forms/FormField";
import { AutoProgressButton } from "../forms/fields/AutoProgressButton";
import { T } from "../../util/t";
import { ListItemContainer } from "../lists/ListItem";
import { useError } from "hooks/useNotifications";
import { ListItemSelectableWithIcon } from "../lists/ListItemSelectable";

export const PropertyLevelStep = ({ organisationId, setStep, closeDialog, property, }) => {
    const { type, values, level, setLevel } = usePropertyWidgetState();
    const { hasError, setSubmitted } = useSelectionRequired(level);
    const { notifyResponseError } = useError();

    const [createProperty] = useMutation(CREATE_ORGANISATION_PROPERTY, {
        update(cache, { data: { createOrganisationProperty: { property } } }) {
            const { organisation } = cache.readQuery({ query: GET_ORGANISATION_WITH_FEDERATION_PROPERTIES, variables: { id: organisationId } });
            cache.writeQuery({
                query: GET_ORGANISATION_WITH_FEDERATION_PROPERTIES,
                variables: { id: organisationId },
                data: { organisation: { ...organisation, customProperties: [...organisation.customProperties, property], federationProperties: [...organisation.federationProperties, property] } }
            });
        },
    });

    const onCreate = useCallback(() => {
        setSubmitted(true);
        return level &&
            createProperty({ variables: { id: organisationId, property: { type, level, ...values } } }).then(closeDialog, notifyResponseError);
    }, [level]);

    const onUser = useCallback(() => setLevel("user"), []);
    const onAthlete = useCallback(() => setLevel("athlete"), []);
    const onRegistration = useCallback(() => setLevel("registration"), []);
    const onBack = useCallback(() => setStep(1), []);

    const classes = useMessageStyles();
    const sharedProps = { hasError, disabled: !!property };

    return (
        <>
            <DialogContent>
                <Typography variant="label1"><T>Field category</T></Typography>
                <ListItemContainer>
                    <ListItemSelectableWithIcon {...sharedProps} isSelected={level === "athlete"} onSelect={onAthlete} icon={<DirectionsRun />} title="Athlete" description="Field will be permanently linked to the athlete’s Liveheats profile. Best for items such as “Gender”, “Sponsors” and “Member ID”" />
                    <ListItemSelectableWithIcon {...sharedProps} isSelected={level === "registration"} onSelect={onRegistration} icon={<Event />} title="Registration" description="Field will be linked to the athlete in this event or series only. Best for items that might change in the future, such as “T-shirt size” or “COVID Declaration”" />
                    <ListItemSelectableWithIcon {...sharedProps} isSelected={level === "user"} onSelect={onUser} icon={<AccountCircle />} title="User" description="Information will be permanently linked to the user who manages the athlete profile (usually the athlete themselves or parent) now and in the future. Best for items such as “Address” or “Emergency contact”" />
                    {hasError && <div className={`${classes.message} ${classes.errorMessage}`}><T>You must select a category</T></div>}
                </ListItemContainer>
            </DialogContent>
            <DialogActions className="sticky-dialog-actions">
                <Button onClick={onBack} className="left" startIcon={<ArrowBack />}>
                    <T>Back</T>
                </Button>
                <Button variant="outlined" onClick={closeDialog}><T>Cancel</T></Button>

                {!property && <AutoProgressButton variant="contained" color="primary" onClick={onCreate}><T>Create</T></AutoProgressButton>}
            </DialogActions>
        </>
    );
};
