import { Component } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";
import * as EventActions from "../../actions/event";
import { EventLink } from "../routes/director/EventLink";
import { Segment } from "../../styleguide/patterns/segment";
import { MaxWidthPageWithHeading } from "../layout/page/MaxWidthPageWithHeading";
import { convertToJS } from "../eventDivisions/helpers";
import { Meta } from "src/components/util/Meta";

export const JudgeDashboard = connect(state => ({ events: state.events.get("list", Immutable.List()) }))(
    class JudgeDashboard extends Component {
        componentDidMount = ({ dispatch, history } = this.props) => {
            dispatch(EventActions.list());
        };

        render = ({ events } = this.props) =>
            <MaxWidthPageWithHeading title="My events">
                <Meta title="Judge Dashboard | Liveheats" />
                <Segment>
                    {events.map(event =>
                        <EventLink key={event.get("id")} event={convertToJS(event)}
                                   link={`/events/${event.get("id")}/scoring`}/>
                    )}
                </Segment>
            </MaxWidthPageWithHeading>;
    });
