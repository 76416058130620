import { typography } from "./typography";
import { CssVarsThemeOptions } from "@mui/material";

export const components: CssVarsThemeOptions["components"] = {
    MuiCssBaseline: {
        styleOverrides: (theme) => ({
            "*": {
                boxSizing: "border-box"
            },
            "input, select": {
                fontFamily: "inherit",
                fontSize: "inherit",
                "&[disabled]": {
                    cursor: "not-allowed"
                }
            },
            body: {
                "& ::selection": {
                    color: theme.palette.text.primary,
                    background: theme.palette.brand.secondary
                },
            },
        })
    },
    MuiStepper: {
        styleOverrides: {
            root: {
                background: "transparent"
            }
        }
    },
    MuiAvatar: {
        styleOverrides: {
            root: {
                width: 30,
                height: 30,
            },
            colorDefault: {
                color: "inherit",
                backgroundColor: "white",
                textTransform: "uppercase",
                ...typography.button2,
            }
        }
    },
    MuiPaper: {
        defaultProps: {
            elevation: 0,
        },
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.text.primary,
            }),
            rounded: ({ theme }) => ({
                borderRadius: theme.spacing(1)
            }),
            outlined: ({ theme }) => ({
                border: `1px solid ${theme.palette.border.main}`
            })
        }
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                padding: 16
            }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: ({ theme }) => ({
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.input.focus,
                    borderWidth: 1,
                }
            }),
            input: ({ theme }) => ({
                padding: theme.spacing(0, 1)
            }),
            notchedOutline: ({ theme }) => ({
                borderColor: theme.palette.input.border,
                "&:hover": {
                    borderColor: theme.palette.input.hover,
                }
            })
        }
    },
    MuiButtonGroup: {
        styleOverrides: {
            grouped: ({ theme }) => ({
                "&:hover": {
                    border: `1px solid ${theme.palette.border.main}`,
                },
                "&.selected": {
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.input.selected,
                    borderColor: theme.palette.input.selected
                }
            })
        }
    },
    MuiButtonBase: {
        styleOverrides: {
            root: {
                "&.Mui-disabled": {
                    opacity: 0.6,
                    color: "inherit",
                    backgroundColor: "inherit"
                }
            }
        }
    },
    MuiIconButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                transition: theme.transitions.create(["background-color", "outline", "opacity"]),
                color: theme.palette.text.primary,
                "&:hover": {
                    backgroundColor: theme.palette.state.action,
                    opacity: .9,
                },
                "&:active": {
                    border: "none",
                    opacity: .9,
                    backgroundColor: theme.palette.state.action
                },
                "&.Mui-disabled": {
                    opacity: 0.6
                },
                "&:focus-visible": {
                    outline: `3px solid ${theme.palette.input.focus}`,
                    outlineOffset: "2px"
                },
                "& .MuiTouchRipple-child": {
                    backgroundColor: theme.palette.state.tapLight,
                }
            })
        }
    },
    MuiButton: {
        styleOverrides: {
            root: ({ theme }) => ({
                boxShadow: "none",
                minHeight: 40,
                minWidth: 120,
                "&.Mui-disabled": {
                    opacity: 0.6,
                    color: "inherit",
                    backgroundColor: "inherit"
                },
                "&:active": {
                    boxShadow: "none"
                },
                "&:focus-visible": {
                    outline: `3px solid ${theme.palette.input.focus}`,
                    outlineOffset: "2px"
                },
                "& .MuiTouchRipple-child": {
                    opacity: 1
                },
                "&:hover": {
                    boxShadow: "none"
                }
            }),
            outlinedSizeSmall: {
                minHeight: 36,
                ...typography.button2
            },
            containedSizeSmall: {
                minHeight: 36,
                ...typography.button2
            },
            textSizeSmall: {
                minHeight: 36,
                ...typography.button2
            },
            containedPrimary: ({ theme }) => ({
                "&:hover": {
                    opacity: .8,
                    backgroundColor: theme.palette.primary.main
                },
                "&:active": {
                    opacity: .9,
                    backgroundColor: theme.palette.primary.main
                },
                "&:visited": {
                    color: theme.palette.primary.contrastText
                },
                "&.Mui-disabled": {
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main
                },
                "&.hideText": {
                    color: "transparent"
                },
                "& .MuiTouchRipple-child": {
                    backgroundColor: theme.palette.state.tapLight,
                }
            }),
            outlinedPrimary: ({ theme }) => ({
                "&&": {
                    border: `1px solid ${theme.palette.border.main}`,
                },
                "&:hover": {
                    opacity: .9,
                    backgroundColor: theme.palette.state.action,
                    color: theme.palette.text.primary
                },
                "&:active, &.Mui-selected": {
                    backgroundColor: theme.palette.state.action,
                    opacity: .9,
                },
                "& .MuiTouchRipple-child": {
                    backgroundColor: theme.palette.state.tapDark,
                }
            }),
            outlinedSecondary: ({ theme }) => ({
                "&&": {
                    backgroundColor: "transparent",
                    border: `1px solid ${theme.palette.border.main}`,
                    color: theme.palette.text.contrastText
                },
                "&:hover": {
                    backgroundColor: theme.palette.state.action,
                    opacity: .9
                },
                "&:active": {
                    backgroundColor: theme.palette.state.action,
                    opacity: .9,
                },
                "& .MuiTouchRipple-child": {
                    backgroundColor: theme.palette.state.tapLight,
                }
            }),
            containedSuccess: ({ theme }) => ({
                "&:hover": {
                    opacity: .8,
                    backgroundColor: theme.palette.success.main
                },
                "&:active": {
                    opacity: .9,
                    backgroundColor: theme.palette.success.main
                },
                "&.Mui-disabled": {
                    color: theme.palette.success.contrastText,
                    backgroundColor: theme.palette.success.main
                },
                "&.hideText": {
                    color: "transparent"
                },
                "& .MuiTouchRipple-child": {
                    backgroundColor: theme.palette.state.tapDark,
                }
            }),
            text: ({ theme }) => ({
                color: theme.palette.text.primary,
                "&:hover": {
                    backgroundColor: theme.palette.state.action,
                    opacity: .9,
                    color: theme.palette.text.primary,
                },
                "&:active": {
                    border: "none",
                    opacity: .9,
                    backgroundColor: theme.palette.state.action,
                    color: theme.palette.text.primary,
                },
                "& .MuiTouchRipple-child": {
                    backgroundColor: theme.palette.state.tapDark,
                }
            })
        }
    },
    MuiLink: {
        defaultProps: {
            variant: "button",
            // @ts-expect-error hard to extends defaultProps
            underline: "never"
        },
        styleOverrides: {
            root: ({ theme }) => ({
                color: "inherit",
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                cursor: "pointer",
                "&[disabled]": {
                    cursor: "default",
                    opacity: 0.6
                }
            }),
            underlineAlways: {
                borderBottom: "none",
                textDecorationColor: "initial"
            },
            underlineNone: {
                borderBottom: "none"
            },
            underlineHover: ({ theme }) => ({
                borderBottom: "none",
                textDecorationColor: "initial",
                textDecoration: "underline",
                color: theme.palette.text.link
            }),
            // @ts-expect-error hard to extend styleOverrides
            underlineNever: {
                textDecoration: "none"
            }
        }
    },
    MuiDrawer: {
        styleOverrides: {
            paperAnchorBottom: ({ theme }) => ({
                "&.MuiPaper-rounded": {
                    borderRadius: theme.spacing(2, 2, 0, 0)
                }
            })
        }
    },
    MuiDialog: {
        styleOverrides: {
            paper: ({ theme }) => ({
                "&:not(.MuiDialog-paperFullScreen)&.MuiPaper-rounded": {
                    borderRadius: theme.spacing(2)
                }
            })
        }
    },
    MuiDivider: {
        styleOverrides: {
            root: ({ theme }) => ({
                borderColor: theme.palette.border.light
            })
        }
    },
    MuiTabs: {
        defaultProps: {
            textColor: "inherit"
        },
        styleOverrides: {
            root: ({ theme }) => ({
                borderBottom: `2px solid ${theme.palette.border.light}`,
                width: "100%",
            }),
            indicator: {
                height: 4,
                borderRadius: 2,
            }
        }
    },
    MuiTab: {
        styleOverrides: {
            root: ({ theme }) => ({
                padding: 0,
                marginRight: theme.spacing(4),
                textTransform: "none",
                fontWeight: "normal",
                "&:last-child": {
                    marginRight: 0,
                },
                "&.Mui-selected": {
                    fontWeight: "bold"
                },
                "&&": {
                    minWidth: 0,
                }
            }),
            textColorInherit: {
                opacity: 1,
            },
            // @ts-expect-error hard to extends styleOverrides
            wrapper: {
                alignItems: "flex-start"
            }
        }
    },
    MuiAutocomplete: {
        styleOverrides: {
            option: ({ theme }) => ({
                "&[aria-selected='true'][aria-selected='true'], &.Mui-focused.Mui-focused[aria-selected='true']": {
                    backgroundColor: theme.palette.secondary.lighter
                },
                "@media (hover: none)": {
                    "&[aria-selected='true']": {
                        backgroundColor: theme.palette.secondary.lighter
                    }
                },
                "&.Mui-focused.Mui-focused": {
                    backgroundColor: theme.palette.secondary.light,
                    "&:active": {
                        backgroundColor: theme.palette.secondary.main,
                    }
                }
            }),
            root: ({ theme }) => ({
                ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                    padding: theme.spacing(0.1, 1)
                },
                "&.Mui-focused .MuiOutlinedInput-root": {
                    outline: `2px solid ${theme.palette.input.focus}`,
                }
            }),
            tag: ({ theme }) => ({
                margin: theme.spacing(0, 1, 0, 0),
                padding: theme.spacing(0.25, 0),
                borderRadius: theme.spacing(1),
                backgroundColor: theme.palette.background.low,
                height: "auto",
                "&.Mui-disabled": {
                    opacity: 1,
                    backgroundColor: theme.palette.background.low,
                }
            }),
            endAdornment: {
                ".MuiOutlinedInput-root &&": {
                    right: 1,
                }
            },
            clearIndicator: ({ theme }) => ({
                padding: theme.spacing(1)
            })
        }
    },
    MuiStep: {
        styleOverrides: {
            root: {
                padding: 0
            }
        }
    },
    MuiStepLabel: {
        styleOverrides: {
            root: {
                flexDirection: "column",
            },
            label: ({ theme }) => ({
                color: theme.palette.text.secondary,
                ...typography.caption
            }),
            iconContainer: ({ theme }) => ({
                paddingRight: 0,
                "&.Mui-completed": {
                    background: `radial-gradient(${theme.palette.text.contrastText} 55%, transparent 56%)`
                }
            }),
            labelContainer: ({ theme }) => ({
                marginTop: theme.spacing(1)
            })
        }
    },
    MuiStepConnector: {
        styleOverrides: {
            root: ({ theme }) => ({
                marginTop: theme.spacing(-3),
                "& .MuiStepConnector-line": {
                    borderColor: theme.palette.border.light,
                    borderRadius: theme.shape.borderRadius / 2
                },
                "&.Mui-active .MuiStepConnector-line": {
                    borderColor: theme.palette.success.light
                },
                "&.Mui-completed .MuiStepConnector-line": {
                    borderColor: theme.palette.success.dark
                }
            }),
            line: {
                borderWidth: 4,
                borderBottomStyle: "none", // these hacks are now needed because of chakra loading some defaults
                borderLeftStyle: "none",
                borderRightStyle: "none",
            }
        }
    },
    MuiStepIcon: {
        styleOverrides: {
            root: ({ theme }) => ({
                color: theme.palette.primary.light,
                "&.Mui-active": {
                    color: theme.palette.success.light
                },
                "&.Mui-completed": {
                    color: theme.palette.success.dark
                }
            }),
            text: {
                display: "none"
            }
        }
    },
    MuiSvgIcon: {
        styleOverrides: {
            fontSizeSmall: {
                fontSize: "16px",
            }
        },
    },
};
