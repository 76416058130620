import { Box, BoxProps } from "@mui/material";
import { Footer } from "./Footer";
import { OrganisationsLogos } from "./OrganisationsLogos";
import { Header } from "./Header";
import { OrganiserPromoCard } from "./OrganiserPromoCard";
import { FeaturedEvents } from "./FeaturedEvents";
import { defaultDescription, defaultTitle, useMeta } from "hooks/useMeta";

export const Section: React.FCWithChildren<BoxProps & {
    maxWidth?: string | number
}> = ({ marginTop = 6, children, maxWidth, ...rest }) =>
    <Box maxWidth={theme => maxWidth || theme.shape.pageMaxWidth} width="100%" mx="auto" marginTop={marginTop} {...rest}>
        {children}
    </Box>;

export const HomePage = () => {
    useMeta({
        title: defaultTitle,
        description: defaultDescription,
    });

    return (
        <>
            <Header />
            <FeaturedEvents />
            <OrganiserPromoCard />
            <OrganisationsLogos/>
            <Footer />
        </>
    );
};
