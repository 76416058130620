import { gql } from "@apollo/client";
import { CompleteHeatFragment } from "./heat";
import { DivisionFragment } from "./division";
import { LeaderboardFragment } from "./leaderboard";
import { EventDivisionTeamLeaderboardFragment } from "./eventDivisionTeamLeaderboard";
import { EventDivisionRankFragment } from "./eventDivisionRank";
import { SponsoredContentFragment } from "../components/layout/sponsors/SponsorContent";

export const FormatDefinitionFragment = gql`
  fragment FormatDefinition on FormatDefinition {
    runBased
    roundBased
    teamLeaderboard
    isTeams
    hasBibs
    hideSeeds
    entriesRandomized
    progression
    runProgression
    manualProgression
    heatSizes
    seeds
    defaultHeatDurationMinutes
    numberOfRounds
    teamConfig {
      athletesPerTeam
    }
  }
`;

const DivisionToSeasonsFragment = gql`
  fragment DivisionToSeasons on EventDivision {
      divisionToSeasons {
          id
          divisionId
          seasonId
          pointAllocation {
              id
              name
          }
      }
  }
`

export const EventDivisionFragment = gql`
  fragment EventDivision on EventDivision {
    id
    contestId
    status
    heatDurationMinutes
    defaultEventDurationMinutes
    formatDefinition {
        ...FormatDefinition
    }
    heatConfig {
        hasPriority
    }
    division {
      ...Division
    }
    eventDivisions {
      id
      division {
        ...Division
      }
      ...DivisionToSeasons
    }
    heats {
      ...CompleteHeat
    }
    leaderboards {
      ...Leaderboard
    }
    template {
        id
        name
    }
    ...DivisionToSeasons
    eventDivisionPointAllocations {
        id
        eventDivisionId
        seasonId
        pointAllocation {
            id
            name
        }
    }
    heatConfig {
        totalCountingRides
        athleteRidesLimit
    }
    entryLimit {
        id
        limit
    }
    seededRounds {
        round
        seeds
    }
    event {
        id
        name
    }
    properties
  }
  ${DivisionFragment}
  ${CompleteHeatFragment}
  ${LeaderboardFragment}
  ${FormatDefinitionFragment}
  ${DivisionToSeasonsFragment}
`;

export const GET_EVENT_DIVISION = gql`
  query getEventDivision($id: ID!) {
    eventDivision(id: $id) {
      ...EventDivision
      sponsoredContents {
        ...SponsoredContent
      }
    }
  }
  ${EventDivisionFragment}
  ${SponsoredContentFragment}
`;


const EntryFragment = gql`
  fragment Entry on Entry {
    id
    bib
    teamName
    seed
    rank
    status
    athlete {
      id
      name
      image
    }
  }
`;

export const GET_EVENT_DIVISION_ENTRIES = gql`
  query getEventDivisionEntries($id: ID!) {
    eventDivision(id: $id) {
      id
      formatDefinition {
        ...FormatDefinition
      }
      division {
        ...Division
      }
      entryLimit {
        id
        limit
      }
      entries {
        ...Entry
      }
      eventDivisions {
        id
        division {
          ...Division
        }
        entryLimit {
          id
          limit
        }
        entries {
          ...Entry
        }
      }
    }
  }
  ${FormatDefinitionFragment}
  ${DivisionFragment}
  ${EntryFragment}
`;

export const GET_EVENT_DIVISION_LEADERBOARD = gql`
  query getEventDivisionLeaderboard($id: ID!, $round: Int) {
    eventDivision(id: $id) {
      id
      leaderboards(round: $round) {
        ...Leaderboard
      }
    }
  }
  ${LeaderboardFragment}
`;

export const GET_EVENT_DIVISION_TEAM_LEADERBOARD = gql`
  query getEventDivisionTeamLeaderboard($id: ID!) {
    eventDivision(id: $id) {
      id
      event {
        id
        name
      }
      formatDefinition {
        runBased
        roundBased
        teamLeaderboard
        isTeams
      }
      heatConfig {
        categories
      }
      teamLeaderboard {
        ...EventDivisionTeamLeaderboard
      }
    }
  }
  ${EventDivisionTeamLeaderboardFragment}
`;

export const GET_EVENT_DIVISION_RANK = gql`
  query getEventDivisionRank($id: ID!, $eventDivisionId: ID) {
    eventDivision(id: $id) {
      id
      formatDefinition {
        runBased
        roundBased
        teamLeaderboard
        isTeams
        teamConfig {
          appraisalLevel
        }
      }
      heatConfig {
        calculator
      }
      division {
        ...Division
      }
      eventDivisions {
        id
        division {
          ...Division
        }
      }
      ranking(eventDivisionId: $eventDivisionId) {
        ...EventDivisionRank
      }
    }
  }
  ${DivisionFragment}
  ${EventDivisionRankFragment}
`;

export const PREVIEW_DRAW = gql`
  query previewEventDivisionDraw($id: ID!, $formatDefinition: FormatDefinitionInput!, $entryLimit: EntryLimitInput) {
    eventDivision(id: $id) {
      id
      previewDraw(formatDefinition: $formatDefinition, entryLimit: $entryLimit) {
        rounds {
          name
          roundPosition
          heats {
            position
            numberOfCompetitors
          }
        }
      }
    }
  }
`;

export const UPDATE_ROUND = gql`
  mutation updateRound($eventDivisionId: ID!, $roundPosition: Int!, $roundName: String!, $heatDurationMinutes: Int) {
    updateRound(input: {eventDivisionId: $eventDivisionId, roundPosition: $roundPosition, roundName: $roundName, heatDurationMinutes: $heatDurationMinutes}) {
      heats {
        id
        round
        heatDurationMinutes
      }
    }
  }
`;

export const UPDATE_EVENT_DIVISION = gql`
  mutation updateEventDivision($eventDivisionId: ID!, $formatDefinition: FormatDefinitionInput, $entryLimit: EntryLimitInput) {
    updateEventDivision(input: {eventDivisionId: $eventDivisionId, formatDefinition: $formatDefinition, entryLimit: $entryLimit}) {
      eventDivision {
        id
        formatDefinition {
          ...FormatDefinition
        }
        entryLimit {
          id
          limit
        }
      }
    }
  }
  ${FormatDefinitionFragment}
`;

export const SAVE_AS_TEMPLATE = gql`
  mutation saveEventDivisionAsTemplate($name: String!, $eventDivisionId: ID!, $organisationId: ID!) {
    saveEventDivisionAsTemplate(input: {name: $name, eventDivisionId: $eventDivisionId, organisationId: $organisationId}) {
      eventDivision {
        id
        template {
          id
          name
        }
      }
    }
  }
`;

export const BasicEventDivisionFragment = gql`
  fragment BasicEventDivision on EventDivision {
    id
    division {
      id
      name
    }
    formatDefinition {
      isTeams
      teamConfig {
        athletesPerTeam
      }
    }
    template {
      id
      name
      teamRoles {
        id
        name
      }
    }
  }
`;

export const GET_EVENT_DIVISION_BASIC_INFO = gql`
  query getEventDivisionBasicInfo($id: ID!) {
    eventDivision(id: $id) {
      ...BasicEventDivision
      eventDivision {
        ...BasicEventDivision
      }
    }
  }
  ${BasicEventDivisionFragment}
`;
