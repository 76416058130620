import { api } from "./api";
import * as NotificationActions from "./notification";
import * as UserActions from "./user";
import { notifyError } from "./error";
import { apolloClient } from "components/providers/ApolloProvider";
import { GET_EVENT } from "src/graphql/event";

export let scheduleAbortController;

export const list = () =>
    dispatch =>
        api.get("events")
            .then(res => dispatch({
                type: "GET_EVENTS",
                res
            }));

export const getSchedule = (event_id) =>
    dispatch => {
        scheduleAbortController = typeof AbortController !== "undefined" && new AbortController();
        return api.get(`events/${event_id}/schedule`, { signal: scheduleAbortController && scheduleAbortController.signal })
            .then(res => {
                scheduleAbortController = null;
                return dispatch({
                    type: "SET_EVENT_SCHEDULE",
                    event_id,
                    data: res.data
                });
            }).catch(e => {
                scheduleAbortController = null;
                if (e.name !== "AbortError") throw e;
            });
    };

export const get = (event_id) =>
    dispatch =>
        api.get(`events/${event_id}`)
            .then(res => dispatch({
                type: "SET_EVENT",
                event_id,
                data: res.data
            }));

export const create = (event) =>
    dispatch =>
        api.post("events", { event })
            .then(res => dispatch({
                type: "CREATE_EVENT",
                res
            }), notifyError(dispatch));

export const getCurrentHeats = (event_id) =>
    dispatch =>
        api.get(`events/${event_id}/current_heats`)
            .then(res => dispatch({
                type: "SET_CURRENT_HEATS",
                event_id,
                data: res.data
            }));

export const update = (id, event) =>
    dispatch =>
        api.put(`events/${id}`, { event })
            .then(() => dispatch(get(id)), notifyError(dispatch))
            .then(() => Promise.all([dispatch(get(id)), apolloClient.query({ query: GET_EVENT, variables: { id }, fetchPolicy: "network-only" })]), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Bueno. Event successfully saved!")));

export const remove = (id) =>
    dispatch =>
        api.delete(`events/${id}`)
            .then(() => dispatch(NotificationActions.success("Event successfully deleted!")));

export const register = (event_id, values) =>
    dispatch =>
        api.post(`events/${event_id}/register`, values)
            .then(() => {
                dispatch(UserActions.getCurrentUser());
            }, notifyError(dispatch));
