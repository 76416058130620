import { useEffect } from "react";
import { useForm, UseFormProps } from "react-hook-form";
import { FieldProps } from "./Field";
import { FieldComponents } from "./FormKit";

export interface UseFormKitProps {
    fields: FieldProps[] | FieldProps[][];
    initialValues: UseFormProps["defaultValues"];
    resetOnSubmit?: boolean;
}

export const useFormKit = ({ fields, initialValues, resetOnSubmit } : UseFormKitProps) => {
    const { handleSubmit, control, getValues, setValue, formState: { isSubmitSuccessful }, reset, setError, trigger, watch } = useForm({
        mode: "onChange",
        defaultValues: initialValues,
    });

    useEffect(() => {
        if (isSubmitSuccessful && resetOnSubmit) reset();
    }, [isSubmitSuccessful, resetOnSubmit]);

    return {
        components: <FieldComponents fields={fields} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch} />,
        control,
        handleSubmit,
        setError,
        getValues,
        setValue,
        trigger
    };
};
