import { PureComponent, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Immutable from "immutable";
import { Field, reduxForm, SubmissionError } from "redux-form/lib/immutable";
import { Link } from "react-router-dom";
import { Link as MuiLink, Stack, Typography } from "@mui/material";

import * as UserActions from "../../actions/user";

import { mustBeEmail, mustHaveLength, plainInput, required } from "../forms";
import { homeForUser } from "../routing/PrivateRoute";
import { OrFacebookLogin } from "../facebook";
import { LoadingButton } from "../actions/loadingButton";
import { AccountFormPage } from "./common";
import { T } from "../util/t";
import { useEnableThirdPartyCookies } from "hooks/useEnableThirdPartyCookies";
import { Notification } from "../layout/notifications/Notification";
import { ListItemContainer } from "components/layout/lists/ListItem";
import { ListItemSelectableWithIcon } from "components/layout/lists/ListItemSelectable";
import { DirectionsRun, Event } from "@mui/icons-material";
import { useMessageStyles } from "components/layout/forms/FormField";
import { trackClick, trackingCategory } from "utils/tracking";
import { useMeta } from "hooks/useMeta";

const mustHave6 = mustHaveLength(6);

class AccountForm extends PureComponent {
    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        preventRedirect: PropTypes.bool,
        dispatch: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        accountType: PropTypes.string,
        setHasError: PropTypes.func,
    };

    state = { submitting: false };

    redirectUserAfterSubmit = ({ data: user }) => {
        const { location, history, preventRedirect, accountType } = this.props;
        if (preventRedirect) return;

        if (accountType === "organisation"){
            return history.push("/organisations/new");
        }

        if (location.state?.from && location.state.from.pathname !== "/organisations/new") {
            return history.push(location.state.from);
        }

        return history.push(homeForUser(user));
    };

    onAccessGranted = values => this.props.dispatch(this.action(values)).then(this.redirectUserAfterSubmit).catch(this.onError);
    onAccessDenied = () => this.setState({ submitting: false, error: "failed_login_open_in_external_tab", errorProps: { url: this.props.location?.state?.from?.pathname || window.location.href } });

    render = () =>
        <>
            <FormWrapper handleSubmit={this.props.handleSubmit} onAccessGranted={this.onAccessGranted} onAccessDenied={this.onAccessDenied} accountType={this.props.accountType} setHasError={this.props.setHasError}>
                {({ onSubmit }) => (
                    <form onSubmit={onSubmit}>
                        {this.state.error && <Notification type="error" className="margin-bottom" text={<T {...this.state.errorProps}>{this.state.error}</T>}/>}

                        {this.fields()}

                        <div className="actions">
                            <LoadingButton
                                variant="contained"
                                action={(e) => onSubmit(e)}
                                disabled={this.props.pristine || this.state.submitting}
                                submitting={this.props.submitting || this.state.submitting}
                                type="submit"
                                color="primary">
                                <T>{this.submitLabel}</T>
                            </LoadingButton>
                        </div>
                    </form>
                )}
            </FormWrapper>

            <OrFacebookLogin
                label={this.FBLabel}
                onClick={() => {
                    if(!this.props.accountType && this.props.setHasError) {
                        this.setState({ submitting: false });
                        this.props.setHasError(true);
                        return true;
                    }
                    else{
                        this.setState({ submitting: true });
                    }
                }}
                onLogin={this.redirectUserAfterSubmit}
                onError={(e) => {
                    this.setState({
                        submitting: false,
                        error: (e.response && e.response.data && e.response.data.error) || "Oops, something went wrong!"
                    });
                }}
            />

            {this.changePageAction && (
                <div className="actions change-page-action">
                    {this.changePageAction()}
                </div>
            )}
        </>;
}

const FormWrapper = ({ children, handleSubmit, onAccessGranted, onAccessDenied, accountType, setHasError }) => {
    const enableThirdPartyCookies = useEnableThirdPartyCookies(onAccessGranted, onAccessDenied);

    const onSubmitFunction = (e) => {
        if (!accountType && setHasError) {
            setHasError(true);
            e.preventDefault();
            return;
        }
        handleSubmit(enableThirdPartyCookies)(e);
    };

    return children({ onSubmit: onSubmitFunction });
};

export const LoginForm = connect()(reduxForm({ form: "login", touchOnChange: true })(
    class LoginForm extends AccountForm {
        FBLabel = "Log in with Facebook";
        submitLabel = "Log in";
        action = values => UserActions.login(values.get("email"), values.get("password"));

        onError = e =>
            this.setState({
                error: (e.response && e.response.status === 401 && "Incorrect email or password") ||
                    "Oops, something went wrong!"
            });

        fields = () =>
            <>
                <Field name="email"
                       label={<T>Email:</T>}
                       type="email"
                       autoComplete="username"
                       component={plainInput}/>

                <Field name="password"
                       label={<T>Password:</T>}
                       type="password"
                       autoComplete="current-password"
                       component={plainInput}/>
            </>;

        changePageAction = () => <MuiLink to={{ pathname: "/forgot-password", state: this.props.location?.state }} component={Link} underline="none"><T>Forgot your password?</T></MuiLink>;
    }));

export const LoginPage = props => {
    useMeta({
        title: "Login to Liveheats",
        description: "Access action sport events and live scoring, anytime, anywhere with Liveheats. Manage your events, results and rankings for surf, snow, skate, surf life saving and more."
    });
    
    const handleClick = () => props.history.push("/sign-up", props.location.state);

    return (
        <AccountFormPage title="Log in to Liveheats" actionText="Don't have an account?" actionButtonText="Sign up" actionClick={handleClick}>
            <LoginForm {...props} />
        </AccountFormPage>
    );
};

LoginPage.propTypes = { history: PropTypes.object.isRequired };

export const SignUpForm = connect()(reduxForm({ form: "sign-up", touchOnChange: true })(
    class SignUpForm extends AccountForm {
        FBLabel = "Sign up with Facebook";
        submitLabel = "Create account";
        action = values => UserActions.register(values.get("email"), values.get("name"), values.get("password"));

        onError = e => {
            if (e.response && e.response.status === 422) {
                this.setState({ error: "email_taken" });
                throw new SubmissionError(Immutable.fromJS(e.response.data.errors).map(e => e.join(", ")).toJS());
            } else {
                this.setState({ error: "Oops, something went wrong!" });
            }
        };

        fields = () =>
            <>
                <Field name="name"
                       label={<T>Full name:</T>}
                       type="text"
                       validate={required}
                       component={plainInput}/>

                <Field name="email"
                       label={<T>Email:</T>}
                       type="email"
                       validate={mustBeEmail}
                       autoComplete="username"
                       component={plainInput}/>

                <Field name="password"
                       label={<T>Password:</T>}
                       type="password"
                       validate={mustHave6}
                       autoComplete="new-password"
                       component={plainInput}/>
            </>;
    }));

export const SignUpPage = props => {
    useMeta({
        title: "Sign up to Liveheats",
        description: "Access action sport events and live scoring, anytime, anywhere with Liveheats. Manage your events, results and rankings for surf, snow, skate, surf life saving and more."
    });
    const handleClick = () => props.history.push("/login", props.location.state);
    const classes = useMessageStyles();
    const [accountType, setAccountType] = useState(props.location?.state?.from.pathname === "/organisations/new" ? "organisation" : null);
    const [hasError, setHasError] = useState(null);

    const onAthleteSelect = () => { setAccountType("athlete"); setHasError(false);};
    const athleteSelectWithTracking = trackClick("Athlete persona button", trackingCategory.signUpFlow, onAthleteSelect);

    const onOrganisationSelect = () => {setAccountType("organisation"); setHasError(false);};
    const organisationSelectWithTracking = trackClick("Organisation persona button", trackingCategory.signUpFlow, onOrganisationSelect);

    return (
        <AccountFormPage title="Create Liveheats account" actionText="Already have an account?" actionButtonText="Log in" actionClick={handleClick}>
            <Stack paddingBottom={4}>
                <Typography variant="label1"><T>How will you use Liveheats?</T></Typography>
                <ListItemContainer>
                    <ListItemSelectableWithIcon icon={<DirectionsRun/>} title="Athlete or supporter" description="I’m looking to find scores, draws or registrations in an organisation that runs on Liveheats" isSelected={accountType==="athlete"} onSelect={athleteSelectWithTracking} hasError={hasError}/>
                    <ListItemSelectableWithIcon icon={<Event/>} title="Organisation" description="I organise competitions and am looking for software tools to improve them" isSelected={accountType==="organisation"} onSelect={organisationSelectWithTracking} hasError={hasError}/>
                    {hasError && <div className={`${classes.message} ${classes.errorMessage}`}><T>You must select a category</T></div>}
                </ListItemContainer>
            </Stack>
            <SignUpForm {...props} setHasError={setHasError} accountType={accountType} />
        </AccountFormPage>
    );
};

SignUpPage.propTypes = { history: PropTypes.object.isRequired };
