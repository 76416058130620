import Immutable from "immutable";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useParams, useRouteMatch } from "react-router";
import { useLazyQuery } from "@apollo/client";

import * as EventDirectorActions from "actions/eventDirector";
import { UnapprovedScoreDialog } from "components/layout/event/head_judge/UnapprovedScoreDialog";
import { getHeatsAndSchedulePosition } from "components/events/helpers";
import { LoadingIconButton } from "components/actions/loadingButton";
import { DidNotCompeteDialog } from "components/events/manage/didNotCompeteDialog";
import { HeatHeader } from "components/events/heats/header";
import { T } from "components/util/t";
import { recursiveCamelToSnake } from "utils/utils";
import { GET_CURRENT_HEATS } from "src/graphql/event";
import { newManageEventPagesPath } from "components/application";
import * as EventActions from "actions/event";
import { useAdminPageContext } from "../page/AdminPageProvider";
import { History, PlayCircle, StopCircle } from "@mui/icons-material";

export const RemoteControl = ({ loadHeats, heats, schedulePosition }) => {
    heats = Immutable.List.isList(heats) ? heats : Immutable.fromJS(heats);
    heats = heats.map(heat => heat.mapEntries(recursiveCamelToSnake(["categories"])));
    const { isRemoteVisible, setIsRemoteVisible } = useAdminPageContext();

    const [heatToHide, setHeatToHide] = useState(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [ridelessAthletes, setRidelessAthletes] = useState();

    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        loadHeats();

        window.addEventListener("focus", loadHeats);
        const swapInterval = setInterval(() => {
            setHeatToHide(heatToHide => heatToHide ? 0 : 1);
        }, 3000);

        return () => {
            clearInterval(swapInterval);
            window.removeEventListener("focus", loadHeats);
        };
    }, []);

    const startHeats = () => dispatch(EventDirectorActions.startHeats(id, loadHeats));
    const undoStartHeats = () => dispatch(EventDirectorActions.undoStartHeats(id, loadHeats));
    const undoEndHeats = () => dispatch(EventDirectorActions.undoEndHeats(id, loadHeats));
    const endHeats = (e, skipRequiresApprovalCheck) => {
        if (!skipRequiresApprovalCheck &&
            heats.some(heat => heat.get("result").some(result => result.get("rides").some(athlete => athlete.some(ride => ride.get("pending")))))) {
            return setOpenDialog("unapproved-score");
        }

        const rideLessAthletes = heats
            .reduce((grouped, heat) =>
                grouped.update(heat.getIn(["config", "calculator"]) || "scoring", athletes => (athletes || Immutable.List()).concat(
                    heat.get("round_position") === 0 ?
                        heat.get("result")
                            .map(result => result.get("rides").valueSeq().flatten().isEmpty() ? result.get("athlete_id") : null)
                            .filter(id => id)
                            .map(id => Immutable.fromJS({
                                id: id,
                                name: heat.getIn(["athletes", "" + id, "name"]),
                                jersey: heat.getIn(["config", "jersey_order", heat.getIn(["athletes", "" + id, "position"])]),
                            })) :
                        Immutable.List()))
            , Immutable.Map());

        return rideLessAthletes.every(athletes => athletes.isEmpty())
            ? dispatch(EventDirectorActions.endHeats(id, undefined, loadHeats))
            : openDidNotCompeteDialog(rideLessAthletes);
    };

    const openDidNotCompeteDialog = (rideLessAthletes) => {
        setOpenDialog("did-not-compete");
        setRidelessAthletes(rideLessAthletes);
    };

    const closeDialog = () => setOpenDialog(false);

    const started = heats.some(heat => heat.get("start_time")),
        eventEnded = schedulePosition !== 0 && heats.isEmpty(),
        action = started ? endHeats : startHeats,
        undoAction = started ? undoStartHeats : undoEndHeats;

    useEffect(() => {
        setIsRemoteVisible(!(schedulePosition === 0 && heats.isEmpty()));
    }, [schedulePosition, heats]);

    return isRemoteVisible &&
        <>
            <div className="remote-control">
                <Link className="heats" to={`/events/${id}/head_judge`}>
                    {heats.map((h, i) =>
                        <HeatHeader
                            key={h.get("id")}
                            forceShowScheduled={true}
                            className={i === heatToHide && heats.size > 1 ? "hide-mobile-landscape" : ""}
                            heat={h.set("bank", heats.size > 1 ? (i === 0 ? "Main bank" : "Secondary bank") : "")}/>
                    )}

                    {eventEnded && <div className="heat-header"><T>That's a wrap! All heats are finished.</T></div>}
                </Link>

                <div className="remote-actions">
                    {(schedulePosition !== 0 || started) &&
                    <LoadingIconButton
                        action={undoAction}
                        className="undo remote-action"
                        label={<T>undo</T>}
                        Icon={History}/>}

                    {!heats.isEmpty() &&
                    <LoadingIconButton
                        action={action}
                        className={started ? "end danger remote-action" : "start success remote-action"}
                        label={<T>{started ? "end" : "start"}</T>}
                        Icon={started ? StopCircle : PlayCircle }/>}
                </div>
            </div>

            <UnapprovedScoreDialog
                open={openDialog === "unapproved-score"}
                type={heats.map(h => h.get("type")).first()}
                eventId={id}
                endHeats={endHeats}
                handleClose={closeDialog}/>

            <DidNotCompeteDialog
                open={openDialog === "did-not-compete"}
                eventId={id}
                loadHeats={loadHeats}
                type={heats.map(h => h.get("type")).first()}
                rideLessAthletes={ridelessAthletes}
                handleClose={closeDialog}/>
        </>;
};

export const EventRemoteControl = connect(state => ({ events: state.events }))(({ events, dispatch }) => {
    const { id } = useParams();
    const { data, refetch } = useLazyQuery(GET_CURRENT_HEATS, { variables: { id }, fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" })[1];
    const newEventManagementPathMatch = useRouteMatch(newManageEventPagesPath);

    if (newEventManagementPathMatch) return (
        <RemoteControl
            loadHeats={refetch}
            heats={data?.event.currentHeats.map(heat => ({
                ...heat,
                athletes: heat.competitors.reduce((athletes, competitor) => {
                    athletes[competitor.athleteId] = { ...competitor, name: competitor.athlete.name };
                    return athletes;
                }, {}),
                division: heat.eventDivision.division.name,
                number: heat.position + 1,
                type: heat.config.runBased ? "run" : "heat"
            })) || []}
            schedulePosition={data?.event.currentScheduleIndex}
        />
    );

    return (
        <RemoteControl
            loadHeats={() => dispatch(EventActions.getCurrentHeats(id))}
            {...getHeatsAndSchedulePosition({ events }, { match: { params: { id } } })}
        />
    );
});
