import { useTranslate } from "components/providers/LocalizationProvider";
import { useEffect } from "react";

export type UseMetaProps = {
    title?: string,
    description?: string,
    translationProps?: Record<string, string>,
    skip?: boolean,
}

export const defaultTitle = "Liveheats | Where action sports history is made";
export const defaultDescription = "Liveheats powers better action sports events for surf, skate, snow and surf life saving. Used by local clubs, regional, state, and national federations around the world for live scoring, heat draws, registrations, rankings and more.";


export const useMeta = ({ title, description, translationProps, skip }: UseMetaProps) => {
    const translatedTitle = useTranslate(title, translationProps);
    const translatedDescription = useTranslate(description, translationProps);

    useEffect(() => {
        if (skip) return;
        ensureMetaTagExists("title", translatedTitle || "");
        ensureMetaTagExists("og:title", translatedTitle || "");
        ensureMetaTagExists("twitter:title", translatedTitle || "");
        ensureMetaTagExists("description", translatedDescription || "");
        ensureMetaTagExists("og:description", translatedDescription || "");
        ensureMetaTagExists("twitter:description", translatedDescription || "");
    }, [skip]);

    useEffect(() => {
        if (skip) return;
        if (translatedTitle) {
            document.title = translatedTitle;
            document.querySelectorAll("meta[name$=\"title\"]").forEach(tag =>
                tag.setAttribute("content", translatedTitle)
            );
        } else {
            document.querySelectorAll("meta[name$=\"title\"]").forEach(tag =>
                tag.setAttribute("content", defaultTitle)
            );
        }
        if (translatedDescription) {
            document.querySelectorAll("meta[name$=\"description\"]").forEach(tag =>
                tag.setAttribute("content", translatedDescription)
            );
        } else {
            document.querySelectorAll("meta[name$=\"description\"]").forEach(tag =>
                tag.setAttribute("content", defaultDescription)
            );
        }
    }, [translatedTitle, translatedDescription, skip]);
};

function ensureMetaTagExists(name: string, content: string) {
    if (!document.head.querySelector(`meta[name="${name}"]`)) {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("name", name);
        metaTag.setAttribute("content", content);
        document.head.appendChild(metaTag);
    }
}
