import { EventStatus } from "src/graphql/generated/types";
import { T } from "../../util/t";
import { StatusPill } from "../StatusPill";
import { EVENT_STATUSES } from "./EVENT_STATUSES";

type EventStatusPillProps = {
    status: EventStatus;
    className?: string;
    onClick?: () => void;
};

export const EventStatusPill: React.FCWithChildren<EventStatusPillProps> = ({
    status,
    children,
    className,
    onClick 
}) => {
    // @ts-expect-error waiting on conversion to TS
    return <StatusPill className={className} status={status} onClick={onClick}><T>{EVENT_STATUSES[status]}</T>{children}</StatusPill>;
};
