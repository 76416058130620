import classnames from "classnames";
import { ArrowOutward } from "@mui/icons-material";

import { NavLink } from "./NavLink";
import { T } from "../../util/t";
import { useSelector } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { GET_EVENT } from "src/graphql/event";
import { makeStyles } from "components/providers/makeStyles";
import { MENU_WIDTH } from "./useSideNavStyles";
import { convertToJS } from "../../eventDivisions/helpers";
import { CompactBadge } from "components/elements/CompactBadge";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 0),
        top: 0,
        position: "sticky",
        minWidth: MENU_WIDTH, // prevent element from shrinking when sidebar is collapsing
    }
}));

const useGetEventConfig = (eventId) => {
    const client = useApolloClient();
    const reduxEvent = useSelector(({ events }) => events.getIn(["mini", parseInt(eventId)]));
    const event = client.readQuery({ query: GET_EVENT, variables: { id: eventId } })?.event || convertToJS(reduxEvent);

    return {
        teamLeaderboard: event?.config?.teamLeaderboard,
        renderPriorityJudge: event?.eventDivisions?.some(eventDivision => eventDivision.heatConfig?.hasPriority),
        priorityEnabled: event?.priorityEnabled
    };
};

export const ManageEventLinks = ({ eventId, clickLink, className }) => {
    const classes = useStyles();
    const { teamLeaderboard, renderPriorityJudge, priorityEnabled } = useGetEventConfig(eventId);

    return (
        <div className={classnames(classes.container, className)}>
            <NavLink to={`/events/${eventId}/dashboard`} onClick={clickLink}><T>Event dashboard</T></NavLink>
            <NavLink to={`/events/${eventId}/edit`} onClick={clickLink}><T>Event settings</T></NavLink>

            <NavLink to={`/events/${eventId}/email-athletes/new`} onClick={clickLink}><T>Email athletes</T></NavLink>

            {teamLeaderboard &&
            <NavLink to={`/events/${eventId}/clubs/club-leaderboard`} onClick={clickLink}><T>Club leaderboard</T></NavLink>}

            <NavLink to={`/events/${eventId}/schedule/edit`} onClick={clickLink}><T>Schedule</T></NavLink>
            <NavLink to={`/events/${eventId}/head_judge`} onClick={clickLink}><T>Head judge</T></NavLink>

            {renderPriorityJudge &&
            <NavLink to={`/events/${eventId}/priority_judge`} onClick={clickLink} target={priorityEnabled && "_blank"}>
                <span><T>Priority judge</T>{priorityEnabled && <ArrowOutward sx={{ verticalAlign: "middle" }}/>}</span>
            </NavLink>}

            <NavLink to={`/events/${eventId}/reports`} onClick={clickLink}><T>Reports</T></NavLink>
            <NavLink to={`/events/${eventId}/event-media-upload`} onClick={clickLink}><T>Event media</T><CompactBadge text="NEW"/></NavLink>
            <NavLink to={`/events/${eventId}`} target="_blank" onClick={clickLink}>
                <span><T>Public event link</T><ArrowOutward sx={{ verticalAlign: "middle" }}/></span>
            </NavLink>
            <NavLink to={`/events/${eventId}/spectate`} target="_blank" onClick={clickLink}>
                <span><T>TV scoreboard</T><ArrowOutward sx={{ verticalAlign: "middle" }}/></span>
            </NavLink>
        </div>
    );
};
