import { api } from "./api";
import * as NotificationActions from "./notification";
import { notifyError } from "./error";
import { apolloClient } from "components/providers/ApolloProvider";
import { GET_EVENT_DIVISION } from "src/graphql/eventDivision";
import { get as getEvent } from "./event";

export const get = (event_division_id) =>
    dispatch =>
        api.get(`event_divisions/${event_division_id}`)
            .then(res => dispatch({
                type: "SET_EVENT_DIVISION",
                event_division_id: parseInt(event_division_id),
                data: res.data
            }), notifyError(dispatch));

export const getDraw = (event_division_id) =>
    dispatch =>
        apolloClient.query({ query: GET_EVENT_DIVISION, variables: { id: event_division_id }, fetchPolicy: "network-only" })
            .catch(notifyError(dispatch));

export const removeRound = (event_division_id, roundPosition, onRemove) =>
    dispatch =>
        api.delete(`event_divisions/${event_division_id}/round/${roundPosition}`)
            .then(() => onRemove ? onRemove() : dispatch(getDraw(event_division_id)), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Voila! Round deleted.")));

export const changeTemplate = (event_division_id, event_id, template_id) =>
    dispatch =>
        api.put(`event_divisions/${event_division_id}/change_template`, { template_id })
            .then(() => Promise.all([dispatch(getEvent(event_id)), dispatch(get(event_division_id)), dispatch(getDraw(event_division_id))]), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Kapow! We've changed the template and reset all settings.")));

export const update = (id, event_division) =>
    dispatch =>
        api.put(`event_divisions/${id}`, event_division)
            .then(() => Promise.all([dispatch(get(id)), dispatch(getDraw(id))]), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Division settings saved!")));

export const redraw = (id, reseed) =>
    dispatch =>
        api.post(`event_divisions/${id}/redraw`, { reseed })
            .then(() => Promise.all([dispatch(get(id)), dispatch(getDraw(id))]), notifyError(dispatch))
            .then(() => dispatch(NotificationActions.success("Your draw is looking good! Redraw successful.")));
