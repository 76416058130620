import { Component } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";

import * as OrganisationActions from "../../actions/organisation";
import * as EventActions from "../../actions/event";
import { EventForm, EventFormSkeleton } from "./manage/eventForm";
import { Heading } from "../layout/page/Heading";
import { eventPropertiesMapper } from "./manage/helpers";
import { Meta } from "src/components/util/Meta";

export const EditEvent = connect((state, props) => ({
    event: state.events.getIn(["mini", parseInt(props.match.params.id)], Immutable.Map()),
    organisation: state.organisations.get("current")
}))(
    class EditEvent extends Component {
        componentDidMount = () => this.props.dispatch(OrganisationActions.getCurrentUserOrganisation());

        updateEvent = (values) => {
            const { event, dispatch, history } = this.props;
            return dispatch(EventActions.update(event.get("id"), eventPropertiesMapper(values, event.get("date"))))
                .then(() => history.push(`/events/${event.get("id")}/dashboard`));
        };

        render = () =>
            <div className="wrapper event-form">
                <Meta title={this.props.event.get("name")
                    ? "event_settings_meta_title"
                    : "Event settings | Liveheats"}
                      translationProps={{ eventName: this.props.event.get("name") }}
                />
                <Heading title="Event settings"/>

                {(this.props.organisation && !this.props.event.isEmpty()) ?
                    <EventForm
                        updateEvent={this.updateEvent}
                        event={this.props.event}
                        organisation={this.props.organisation}
                        location={this.props.location} /> :

                    <EventFormSkeleton/>
                }
            </div>;
    });
